/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    safe2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 2.5A1.5 1.5 0 012.5 1h12A1.5 1.5 0 0116 2.5v12a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 011 14.5V14H.5a.5.5 0 010-1H1V9H.5a.5.5 0 010-1H1V4H.5a.5.5 0 010-1H1zM2.5 2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5z"/><path pid="1" d="M5.035 8h1.528q.072-.277.214-.516l-1.08-1.08A3.5 3.5 0 005.035 8m1.369-2.303l1.08 1.08q.24-.142.516-.214V5.035a3.5 3.5 0 00-1.596.662M9 5.035v1.528q.277.072.516.214l1.08-1.08A3.5 3.5 0 009 5.035m2.303 1.369l-1.08 1.08q.142.24.214.516h1.528a3.5 3.5 0 00-.662-1.596M11.965 9h-1.528q-.072.277-.214.516l1.08 1.08A3.5 3.5 0 0011.965 9m-1.369 2.303l-1.08-1.08q-.24.142-.516.214v1.528a3.5 3.5 0 001.596-.662M8 11.965v-1.528a2 2 0 01-.516-.214l-1.08 1.08A3.5 3.5 0 008 11.965m-2.303-1.369l1.08-1.08A2 2 0 016.563 9H5.035c.085.593.319 1.138.662 1.596M4 8.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0m4.5-1a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
